var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper member-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("View Members")]), _c('button', {
    staticClass: "clear-search",
    on: {
      "click": _vm.clearData
    }
  }, [_vm._v("Clear Search")])]), _c('client-search', {
    attrs: {
      "filters": _vm.filterQuery,
      "clear": _vm.clear
    },
    on: {
      "onSearch": _vm.filterData
    }
  }), _c('div', {
    staticClass: "row member-card member-card-list"
  }, [_c('div', {
    ref: "memberListing",
    staticClass: "clientsData-wrap"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center top-pagination"
  }, [_c('page-result', {
    attrs: {
      "rows": _vm.memberLists
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.memberLists = $event;
      }
    }
  }), _c('pagination', {
    attrs: {
      "rows": _vm.memberLists,
      "filters": _vm.filterQuery
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.memberLists = $event;
      }
    }
  })], 1), _c('clients-data', {
    attrs: {
      "memberLists": _vm.memberLists
    },
    on: {
      "onSearch": _vm.filterData
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('page-result', {
    attrs: {
      "rows": _vm.memberLists
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.memberLists = $event;
      }
    }
  }), _c('pagination', {
    attrs: {
      "rows": _vm.memberLists,
      "filters": _vm.filterQuery
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.memberLists = $event;
      }
    }
  })], 1)], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="page-wrapper member-wrap">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h1>View Members</h1>
        <button class="clear-search" @click="clearData">Clear Search</button>
      </div>
      <client-search
        :filters="filterQuery"
        :clear="clear"
        @onSearch="filterData"
      />

      <div class="row member-card member-card-list">
        <div class="clientsData-wrap" ref="memberListing">
          <div class="d-flex justify-content-between align-items-center top-pagination">
            <page-result :rows.sync="memberLists"/>
            <pagination :rows.sync="memberLists" :filters="filterQuery" />
          </div>
          <clients-data @onSearch="filterData" :memberLists="memberLists" />
          <div class="d-flex justify-content-between align-items-center">
            <page-result :rows.sync="memberLists"/>
            <pagination :rows.sync="memberLists" :filters="filterQuery" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Helper from "@/resource/Helper";
import ClientsData from "@/views/MemberList/partials/ClientsData.vue";
import ClientSearch from "@/views/MemberList/partials/ClientSearch.vue";

const filterData = {
  first_name: "",
  last_name: "",
  agent_code: "",
  status: "",
  enrollment_date: "",
  state: "",
  effective_date: "",
  enrollment_from: "",
  enrollment_to: "",
  group_id: "",
  rep_id: "",
  policy_id:"",
  carrier_id: [],
  plan_id: [],
  type:"",
};
export default {
  name: "ViewMembers",
  components: {
    ClientSearch,
    ClientsData,
  },
  data() {
    return {
      filterQuery: { ...filterData },
      memberLists: [],
      pagination: {
        page: 1,
      },
      clear: false,
      breadcrumbItems: [{ title: "View Members", active: true }],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    clearData() {
      this.filterQuery = { ...filterData };
      this.clear = !this.clear
      this.getData(this.filterQuery);
      
    },
    filterData(filters) {
      this.filterQuery = filters;
      this.getData(this.filterQuery);
    },
    async getData(filters = []) {
      let url;
      if (filters) {
        let filterUrl = Helper.objToUrlParams(filters);
        url = this.$endpoint.GET_MEMBERS + `?${filterUrl}`;
      } else {
        url = this.$endpoint.GET_MEMBERS;
      }
      
      let loader = this.$loading.show();
      await axios
        .get(url)
        .then((res) => {
          this.memberLists = res.data;
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>
<style src="@/assets/css/viewclients.css"></style>
